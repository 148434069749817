import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwamerchantservices.com/wp-content/uploads/2015/12/EMV-NFC.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAARlAAAEZQAGA43XUAAABxElEQVQY01VQPWgUURDeSiv7pLPQInIWIiJYpbEyECOCP9gcBrzGwsMIIUUstLgghij+YBqJIkh+Dg5kTSK5hCTkkrgmGnJ6Zy459XTXvd19u9nd29u3b96Et2rhwAzMN/PNNzMSItYDWNGo6gPGFjL+2aRreriihZsGDRlHxC+ELWv0Yz0qWdF6nZoBQ0SpEfENIyparESY1fzHB6z6vEgg7kEegzUXPuh0w6ARAxAQl0QAEGkcAQTftu1fqhoEge8QCFxEjItMNPB4FKMIIOH/xpjQGhq8f+F858ziUvZBpjAyJAS48JjHdz8tKfdu1SYeSU1Hryuz5cLc1k71a6ViWBYiZh4+O96VzMu5hmMTrcY5LBcKi6tKsVIdnxi7nTw3eefGVu65VHra33fl7MHWlsMdFw8dSaTTafTNm9e6W9uOrc/If9Z5rygH9u9rOdl+4vSZROLoaDar67/FwzZfD+enJ/uuXh5Mp4afPJZlmXt2JnWps/3Ut51t5BwANFWdmp56mx190Zsa6UkaSh4Z3W0Ef29u+l7TdzliSKPvC7nyywHyY9txPdM0CSGe5zHGIoCf5eJa7tW7u9cr828c194DxkCNUqJipwoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EMV NFC",
            "title": "EMV NFC",
            "src": "/static/b308ad26b2f8166af2e7fa09b14661a5/fb9bc/EMV-NFC-300x117.png",
            "srcSet": ["/static/b308ad26b2f8166af2e7fa09b14661a5/53d97/EMV-NFC-300x117.png 100w", "/static/b308ad26b2f8166af2e7fa09b14661a5/bbdec/EMV-NFC-300x117.png 200w", "/static/b308ad26b2f8166af2e7fa09b14661a5/fb9bc/EMV-NFC-300x117.png 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`One of the more perplexing elements of the slow, long-awaited march to EMV-chip cards in the U.S. is the lack of awareness that still pervades among merchants and consumers alike.`}</p>
    <p>{`At the same time, mobile wallets such as Apple Pay have had no trouble becoming household names despite having fairly low adoption.`}</p>
    <p>{`Three months past the U.S. EMV liability shift — the soft deadline set by the card networks for EMV adoption — Scott Holt, a vice president at Ingenico in North America, sees a chance to finally shift consumers away from their old payment methods. The twist is that it won't be by consumers' choice.`}</p>
    <p>{`By the end of 2016, much of the U.S. retail market will be compliant with EMV and mobile wallets, Holt said. Consumers will have no choice but to abandon magnetic stripe payments, but when they do it will open up the opportunities to choose something other than EMV's chip-and-dip process, he said. "People will have to learn a new experience with EMV anyway."`}</p>
    <p>{`The migration to both Near Field Communication-based wallets and EMV will place pressure on floor-level personnel at retailers to educate consumers on the new technologies. In particular, cashiers are the key to a smooth transition to new payments technology, as Beatta McInerney, a business development manager of payments for point of sale technology company ScanSource POS and Barcode, explains in recent column for PaymentsSource.`}</p>
    <p>{`Retailers must train cashiers to spot if a consumer has an EMV chip even if the merchant is not accepting EMV cards at this point, McInerney said. This gives retailers an opportunity to teach the process at a more measured pace.`}</p>
    <p>{`"Consumer experience is largely dependent on the education of retailers," Holt said, adding that includes the skills to recognize and help consumers who are confused about how the new terminals work. Placing the right prompts into point of sale software is also important, he said.`}</p>
    <p>{`The delays aside, Ingenico reports the EMV migration in the U.S. is progressing similar to other countries, adding it's tracking the migration to tailor the education it will provide to merchants over the course of the next year.`}</p>
    <p>{`Read the rest of this article at `}<a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/retail-acquiring/how-can-merchants-close-the-emv-nfc-knowledge-gap-3023028-1.html?utm_medium=email&ET=paymentssource:e4988932:a:&utm_source=newsletter&utm_campaign=-dec%2017%202015&st=email"
      }}>{`PAYMENTSSOURCE.COM`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      